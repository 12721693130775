import { FC } from "react"

import { NavLink, useLocation } from "react-router-dom"

import Navigation from "./Navigation"

const menuItems = [
  { label: <NavLink to={"/dashboard"}>Dashboard</NavLink>, key: "Dashboard" },
  { label: <NavLink to={"/orders"}>Orders</NavLink>, key: "Orders" },
  { label: <NavLink to={"/items"}>Items</NavLink>, key: "Items" },
  { label: <NavLink to={"/customers"}>Customers</NavLink>, key: "Customers" },
  { label: <NavLink to={"/employees"}>Employees</NavLink>, key: "Employees" },
]

const HeaderMenu: FC = () => {
  const { pathname } = useLocation()
  return <Navigation mode={"horizontal"} activeKey={pathname} defaultActiveFirst items={menuItems} />
}
export { HeaderMenu }
