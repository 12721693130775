import { DownOutlined, UserOutlined } from "@ant-design/icons"
import type { MenuProps } from "antd"
import { Avatar, Button, Col, Dropdown, Flex, Row, Space } from "antd"
import { FC } from "react"
import { NavLink, useLocation } from "react-router-dom"
import useAuthStore from "../../state/authStore"
import { Logo } from "../logo"
import { HeaderMenu } from "../menu"
import "./index.less"

const Header: FC<{ renderMenu?: FC }> = ({ renderMenu: HorizontalMenu = HeaderMenu }) => {
  const { logout } = useAuthStore()
  const { pathname } = useLocation()

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <NavLink to={"/account"}>Account</NavLink>,
    },
    {
      key: "2",
      label: (
        <Button onClick={logout} type={"link"} danger style={{ color: "red" }}>
          Log out
        </Button>
      ),
    },
  ]

  if (pathname === "/") {
    return (
      <Flex align={"center"} justify={"center"} className={"header-container"}>
        <Logo />
      </Flex>
    )
  }

  return (
    <Flex align={"center"} justify={"space-between"} className={"header-container"}>
      <Col>
        <Logo />
      </Col>
      <Col className={"header-menu"}>
        <HorizontalMenu />
      </Col>
      <Col className={"header-avatar"}>
        <Avatar icon={<UserOutlined />} />
        <Dropdown placement='bottomRight' trigger={["click"]} menu={{ items }}>
          <Space style={{ cursor: "pointer" }}>
            John Doe
            <DownOutlined />
          </Space>
        </Dropdown>
      </Col>
    </Flex>
  )
}

export { Header }
