import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      lineType: undefined,
      lineWidth: undefined,
    },
    Layout: {
      headerBg: "#fff",
      bodyBg: "#E7EBF2",
    },
    Typography: {
      fontSizeHeading1: 40,
      fontSizeHeading2: 32,
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
    Button: {
      borderRadius: 0,
      colorBgContainerDisabled: "#BCC6CD",
      colorBgContainer: "#0065A4",
      colorTextDisabled: "#4F5E68",
      colorText: "#fff",
      controlHeight: 48,
      colorLink: "black",
    },
    Table: {
      headerBg: "#fff",
      headerSplitColor: "#fff",
      headerColor: "#6E7986",
      fontWeightStrong: 400,
      borderRadius: 0,
    },
    Pagination: {
      itemActiveBg: "transparent",
      colorPrimary: "#0065A4",
      borderRadius: 0,
    },
    Select: {
      borderRadius: 0,
      colorBorder: "transparent",
    },
    Input: {
      borderRadius: 0,
      colorBorder: "transparent",
      hoverBorderColor: "transparent",
      colorPrimary: "transparent",
      colorPrimaryActive: "transparent",
      activeShadow: "none",
    },
    Modal: {
      borderRadiusLG: 0,
    },
  },
}

export default theme
