import { Row, Space } from "antd"
import { FC } from "react"

import { ReactComponent as LogoImage } from "./Logo.svg"

const Logo: FC = () => {
  return (
    <Row>
      <LogoImage />
    </Row>
  )
}

export { Logo }
