import { FC, lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"
import PrivateRoute from "src/utils/PrivateRoute"

import DefaultLayout, { Loader } from "../components/layout"

const Login = lazy<FC>(() => import("./login"))
const Orders = PrivateRoute(lazy<FC>(() => import("./orders")))
const Items = PrivateRoute(lazy<FC>(() => import("./items")))
const Customers = PrivateRoute(lazy<FC>(() => import("./customers")))
const Employees = PrivateRoute(lazy<FC>(() => import("./employees")))
const Account = PrivateRoute(lazy<FC>(() => import("./account")))
const Dashboard = PrivateRoute(lazy<FC>(() => import("./dashboard")))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Login,
        path: "",
        index: true,
      },
      {
        Component: Dashboard,
        path: "/dashboard",
        index: true,
      },
      {
        Component: Orders,
        path: "/orders",
        index: true,
      },
      {
        Component: Items,
        path: "/items",
        index: true,
      },
      {
        Component: Customers,
        path: "/customers",
        index: true,
      },
      {
        Component: Employees,
        path: "/employees",
        index: true,
      },
      {
        Component: Account,
        path: "/account",
        index: true,
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
]

export default routes
