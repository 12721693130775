import React from "react"
import { Navigate, useLocation } from "react-router-dom"
import useAuthStore from "src/state/authStore"

const PrivateRoute = (Component: React.ComponentType) => {
  return (props: any) => {
    const { user } = useAuthStore()
    console.log(user)
    if (!user) {
      // Перенаправление неавторизованного пользователя на страницу входа
      return <Navigate to='/' replace />
    }
    // Рендеринг компонента, если пользователь авторизован
    return <Component {...props} />
  }
}

export default PrivateRoute
