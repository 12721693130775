import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Footer, Header } from "."
import { HeaderMenu } from "../menu"

const Layout: FC = () => (
  <BaseLayout>
    <BaseLayout.Header>
      <Header renderMenu={HeaderMenu} />
    </BaseLayout.Header>
    <Suspense>
      <Outlet />
    </Suspense>
  </BaseLayout>
)

export { Layout }
