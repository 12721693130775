import { FirebaseError } from "firebase/app"
import { User, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth"
import { doc, getDoc } from "firebase/firestore"
import { create } from "zustand"
import { auth, firestore } from "../api/firebase"

interface AuthState {
  user: User | null
  role: string | null
  error: string | null | FirebaseError
  setUser: (user: User | null) => void
  setRole: (role: string | null) => void
  setError: (error: string | null) => void
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

const useAuthStore = create<AuthState>(set => ({
  user: null,
  role: null,
  error: null,
  setUser: user => set({ user }),
  setRole: role => set({ role }),
  setError: error => set({ error }),
  login: async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      const userDocRef = doc(firestore, "users", userCredential.user.uid)
      const userDoc = await getDoc(userDocRef)

      if (userDoc.exists() && userDoc.data().role === "admin") {
        set({ user: userCredential.user, role: userDoc.data().role })
        console.log("Admin logged in:", userCredential.user)
      } else {
        console.log("Unauthorized: Only admins can log in.")
        throw new Error("Unauthorized: Only admins can log in.")
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        set({ error: error.message })
      } else {
        set({ error: "An unexpected error occurred" })
      }
    }
  },
  logout: async () => {
    await signOut(auth)
    set({ user: null, role: null }) // Сброс пользователя и роли при выходе
  },
}))

onAuthStateChanged(auth, async user => {
  if (user) {
    const userDocRef = doc(firestore, "users", user.uid)
    const userDoc = await getDoc(userDocRef)
    if (userDoc.exists() && userDoc.data().role === "admin") {
      useAuthStore.getState().setUser(user)
      useAuthStore.getState().setRole(userDoc.data().role)
    } else {
      useAuthStore.getState().setUser(null)
      useAuthStore.getState().setRole(null)
      await signOut(auth)
    }
  }
})

export default useAuthStore
