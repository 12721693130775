// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAaZJkzX_bSbngmAWDgjQrftai99XWh1qU",
  authDomain: "carely-d0370.firebaseapp.com",
  projectId: "carely-d0370",
  storageBucket: "carely-d0370.appspot.com",
  messagingSenderId: "6573865096",
  appId: "1:6573865096:web:0ceb46f92b50f82461073d",
  measurementId: "G-VWNEWYZX1K",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const firestore = getFirestore(app)

export { analytics, app, auth, firestore }
